import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css'; 

const APPLICATION_PDF_FILE_URL = '/downloads/Application_Form.pdf'
const TIMESHEET_PDF_FILE_URL ='/downloads/Timesheet.pdf'

function ContactBox () {
    const downloadFileAtURL=(url)=>{
      const fileName = url.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };
    return (  
        <div className='s-box'>
           
            <div className='contactIcons'>
            <h1 className='box-header'>Please get in touch</h1>
                <FontAwesomeIcon icon={faWhatsapp} className='whatsApp'></FontAwesomeIcon>
                <span class="calendar-day">07957816070</span>
                <FontAwesomeIcon icon={faPhone} className='phone'></FontAwesomeIcon>
                <span class="calendar-day">0160 4455462</span>
                <FontAwesomeIcon icon={faEnvelope} className='envelope'></FontAwesomeIcon>
                <span class="calendar-day">info@firstraterecruit.co.uk</span>
                <div>
                    <button class='btnAppFormDownload' onClick={()=>{downloadFileAtURL(APPLICATION_PDF_FILE_URL)}}>
                    <FontAwesomeIcon icon={faDownload} className='download'></FontAwesomeIcon>
                    Application form
                </button>
                <button class='btnTSheetDownload' onClick={()=>{downloadFileAtURL(TIMESHEET_PDF_FILE_URL)}}>
                    <FontAwesomeIcon icon={faDownload} className='download'></FontAwesomeIcon>
                    Time sheet
                </button>
                </div>
                <span class="calendar-day">Download a document</span>
            </div>
        </div>
    );
}
 
export default ContactBox;