import React from 'react';
import logo from '../../src/images/logo.png'
function Header () {
    return (  
        <div id='mainimg'>
            <div className='name'>
                <h1><span>First Rate</span></h1>
                <h1>Recruit</h1>
                <p className='details'>We recruit for nursing, residential, mental health, learning disability and home settings</p>
                <div className='logodiv'><img src={logo} alt='logo' className='logoH'/></div>
            </div>
        </div>
    );
}
 
export default Header;