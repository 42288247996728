import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav, Navbar } from 'react-bootstrap'
import logo from '../../src/images/logo.png'
import './Menu.css'

function Menu (){
    return(
        <div>
        <Navbar className='navbar' bg="dark" variant="dark"
        expand="sm" collapseOnSelect>
        <Navbar.Brand>
          <img src={logo} alt='logo' className='logo'/>
        </Navbar.Brand>

        <Navbar.Toggle className="coloring" />
        <Navbar.Collapse>
          <Nav className='nav-cont'>
            <Nav.Link className='active' href="#main">Home</Nav.Link>
            <Nav.Link className='about' href="#about">About</Nav.Link>
            <Nav.Link className='services' href="#services">Services</Nav.Link>
            <Nav.Link className='contact' href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </div>

    );
}

export default Menu;