import React from 'react';
import { Grid, Typography } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

function Footer (){
    return(
        
        <div className='footer'>
            <Grid container
            sx={{paddingTop: 6}}
            spacing={0}
        direction="column"
        alignItems="center"
        >
          <Grid item xs={1}>
          <WhatsAppIcon sx={{color:'green'}}/>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{color:'white'}}>07518609649</Typography>
          </Grid>
          <Grid item xs={1}>
          <LocalPhoneIcon sx={{color:'blue'}}/>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{color:'white'}}>0160 4455462</Typography>
          </Grid>
          <Grid item xs={1}>
          <EmailIcon sx={{color:'blue'}}/>
          </Grid>
          <Grid item xs={0}>
          <Typography sx={{color:'white'}}>info@firstraterecruit.co.uk</Typography>
          </Grid>
          <br/>
          <Grid>
            <Typography sx={{color:'white'}}>
            &copy;{new Date().getFullYear()} First Rate Recruit All rights reserved
            </Typography>
          </Grid>
            </Grid>
        </div>
        
    );

}

export default Footer;