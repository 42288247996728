import React from 'react';

function Box1 (props) {
    return (  
        <div className='s-box'>
            <div className='s-b-img'>
                <img src={props.image} alt={props.alt}></img>
            </div>
            <div className='s-b-text'>
            <h1 className='box-header'>Supported living staff</h1>
                <p>First Rate Recruit supply highly qualified professionals that are fully trained.</p>
            </div>

        </div>
    );
}
 
export default Box1;