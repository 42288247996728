import React from 'react';

function ContactMap () {
    return ( 
        <div className='s-box'>
            <iframe className='g-map' title='this is a unique title' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.4275977522952!2d-0.880037248448411!3d52.2174487662596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48770f324c549e81%3A0x6479c523fafc8140!2sRegus%20-%20Northampton%20Business%20Park!5e0!3m2!1sen!2suk!4v1663920003285!5m2!1sen!2suk"
                 height="450"></iframe>
        </div>
    );
}
 
export default ContactMap;