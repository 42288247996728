import React from 'react';

function Box3 (props) {
    return (  
        <div className='s-box'>
            <div className='s-b-text3'>
            <h1 className='box3-header'>What we do</h1>
                <p className='details'>First Rate Recruit is a recruitment health care agency supplying skilled workers in Northampton and its environs.</p>
                <p className='details'>We provide an exceptional high quality, fast, friendly, flexible care solutions to nursing, residential, mental health, learning disability and home settings. </p>
                <p className='details'>We provide individualized care 24 hours 365 days at 98 percent fill rate.</p>
             </div>

        </div>
    );
}
 
export default Box3;