import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './components/Header';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import Menu from './components/Menu';
import Footer from './components/Footer';
import NavbarBelow from './components/NavbarBelow';

function App() {
  return (
    <div className="App">
      <div className='menuDiv'>
        <Menu/>
        </div>
        <NavbarBelow/>
        <div className='headerDivInAppjs'>
        <Header/>
        </div>
      <div className="content">
      <About/>
      <Services/>
      <Contact/>
      </div>  
      <Footer/> 
    </div>
  );
}

export default App;