import React from 'react';
import ContactBox from './ContactBox';
import ContactMap from './ContactMap';

function Contact () {
    return (  
        <div id='contact'>
            <div className='s-heading'>
        <h1>Contact us</h1>
      
      </div>
      <div className='b-container'>
            <ContactBox/>
            <ContactMap/>
      </div>
        </div>
    );
}
 
export default Contact;