import React from 'react';
import Box3 from './Box3';
import BoxAbout from './Box-About';
import image2 from '../images/carers.png';

function About () {
    return (  
        <div id='about'>
            <div className='s-heading'>
            <div className='s-heading'>
        <h1 className='aboutTitle'>About us</h1>
        <p className='profile-p'>
          First Rate Recruit is a recruitement agency that specializes in recruiting and supplying highly experienced, qualified and vetted healthcare professionals.
		 We supply health care staff to care homes and domicialiary care settings.
          </p>
      </div>
      </div>
      <div className='b-container'>
      <Box3/>
        <BoxAbout image={image2} alt='image2'/>
      </div>
        </div>
    );
}
 
export default About;